import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Container} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import AddMovement from "../../components/mobile/AddMovement";
import Grid from "@material-ui/core/Grid";
import MovementTimeline from "../../components/desktop/MovementTimeline";
import {makeStyles} from "@material-ui/core/styles";
import MobileNavigation from "../../components/mobile/MobileNavigation";
import {RouteComponentProps} from "react-router-dom";
import FilterForm from "../../components/common/filters/FilterForm";
import FilterStore from "../../stores/FilterStore";
import FiltersContainer from "../../components/common/filters/FiltersContainer";

interface IProps {
    route: RouteComponentProps;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    '& > *': {
        margin: theme.spacing(0.5),
    },
}));

const WebHomePage = (props: IProps) => {

    const classes = useStyles();

    const filterStore = useContext(FilterStore);

    return (

        <Container component="main" style={{display: 'flex', flexDirection: "column"}}>
            <CssBaseline/>
            <div className={classes.paper}>
                <Grid container spacing={2}>
                    <FiltersContainer route={props.route} />
                    <Grid item xs={12} style={{height: 'calc(100vh - 200px)', overflow: 'auto'}}>
                        <MovementTimeline/>
                    </Grid>
                </Grid>
            </div>
            <MobileNavigation route={props.route}/>
            <AddMovement/>
            {filterStore.isFormOpen && <FilterForm/>}
        </Container>
    );
};

export default observer(WebHomePage);


export const formatMoney = (money: number) => {
    // todo use global locale
    const formatter = new Intl.NumberFormat('bg-BG', {
        style: 'currency',
        currency: 'BGN',
    });

    return formatter.format(money);
}

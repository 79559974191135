import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {IPage} from "../interfaces/IPage";

class NavigationStore {

    public PAGE: IPage[] = [
        {
            key: 'home',
            label: 'Начало',
        },
        {
            key: 'statistics',
            label: 'Статистика',
        },
        {
            key: 'nearby',
            label: 'В близост',
        },
        {
            key: 'settings',
            label: 'Настройки',
        },
    ];

    constructor() {
        reaction(() => this.selectedPage, _ => {});
        this._selectedPage = {
            key: 'home',
            label: 'Начало',
        };
    }

    @observable _selectedPage: IPage;

    @action setSelectedPage(page: IPage) {

        this._selectedPage = page;
    }

    @computed get selectedPage() {

        return this._selectedPage;
    }
}

export default createContext(new NavigationStore());

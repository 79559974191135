import React from 'react';
import {RouteComponentProps} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MobileNavigation from "../../components/mobile/MobileNavigation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TagsSettings from "../../components/common/tags/TagsSettings";
import Typography from "@material-ui/core/Typography";
import EstablishmentsSettings from "../../components/common/establishments/EstablishmentsSettings";

interface IProps {
    route: RouteComponentProps;
}

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        height: "100vh",
    },
    topPanel: {
        flex: 1,
        borderTop: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
    bottomPanel: {
        flex: 10,
        overflow: "auto",
    },
    middlePanel: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        borderBottom: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const SettingsPage = (props: IProps) => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item xs={12} className={classes.topPanel}>
                <MobileNavigation route={props.route}/>
            </Grid>
            <Grid item xs={12} className={classes.middlePanel}>
                <Grid item sm={12} md={12} lg={12} xs={12}>
                    <Typography
                        component="h3"
                        variant="h3"
                        color="textPrimary" align="center">Settings</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomPanel}>
                <Accordion expanded={expanded === 'tags'} onChange={handleChange('tags')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Тагове</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TagsSettings/>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'establishments'} onChange={handleChange('establishments')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Получател</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EstablishmentsSettings/>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default SettingsPage;

import React from 'react';
import {formatMoney} from "../../../utils/MoneyFormatter";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, Theme} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {IStatisticsRow} from "../../../interfaces/IStatistics";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

interface IProps {
    row: IStatisticsRow;
}

const StatisticsListItem = (props: IProps) => {

    return (
        <StyledTableRow key={props.row.name}>
            <StyledTableCell component="th" scope="row">
                {props.row.name}
            </StyledTableCell>
            <StyledTableCell align="right">{formatMoney(props.row.sum)}</StyledTableCell>
        </StyledTableRow>
    );
};

export default StatisticsListItem;

import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TagStore from "../../../stores/TagStore";
import ConfirmDelete from "./ConfirmDelete";
import {IDeleteItem} from "../../../interfaces/IDeleteItem";
import {IEditItem} from "../../../interfaces/IEditItem";
import TagsSettingsEdit from "./TagsSettingsEdit";

const TagsSettingsList = () => {

    const tagsStore = useContext(TagStore);
    const [deleteItem, setDeleteItem] = useState<IDeleteItem | null>(null);
    const [editItem, setEditItem] = useState<IEditItem | null>(null);

    if (tagsStore.tags === null) {

        tagsStore.loadTags();
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Таг</TableCell>
                            <TableCell align="right">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tagsStore.tags && tagsStore.tags.map((tag) => (
                            <TableRow key={tag.id}>
                                <TableCell component="th" scope="row">
                                    {tag.name}
                                </TableCell>
                                <TableCell align="right">
                                    <Button onClick={() => setEditItem({
                                        type: 'Таг',
                                        fieldName: "name",
                                        fieldLabel: "Име: ",
                                        id: tag.id,
                                        name: tag.name,
                                        linkedWith: {
                                            type: "Движения",
                                            elements: [],
                                        }
                                    })}>
                                        <EditIcon/>
                                    </Button>
                                    <Button onClick={() => setDeleteItem({
                                        type: 'Таг',
                                        id: tag.id,
                                        name: tag.name,
                                    })}>
                                        <DeleteIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {deleteItem && <ConfirmDelete deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>}
            {editItem && <TagsSettingsEdit editItem={editItem} setEditItem={setEditItem}/>}
        </div>
    );
};

export default observer(TagsSettingsList);

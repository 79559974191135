import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import NewItemForm from "../common/NewItemForm";
import green from "@material-ui/core/colors/green";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import Zoom from '@material-ui/core/Zoom';

interface IProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
            position: 'relative',
            minHeight: 200,
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(5),
            right: theme.spacing(2),
        },
        fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
    }),
);

const AddMovement = (props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    if (isOpen) {

        return <NewItemForm setIsOpen={setIsOpen}/>;
    }

    return (
        <Zoom
            key="primary"
            in={true}
            timeout={transitionDuration}
            style={{
                transitionDelay: `${transitionDuration.exit}ms`,
            }}
            unmountOnExit
        >
            <Fab aria-label="add" color="primary" className={classes.fab} onClick={() => setIsOpen(true)}>
                <AddIcon/>
            </Fab>
        </Zoom>
    );
};

export default observer(AddMovement);

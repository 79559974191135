import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import NativeSelect from "@material-ui/core/NativeSelect";
import {format} from "date-fns";
import {FILTER_DATE_FORMAT, PERIOD_DISPLAY_DATE_FORMAT} from "../../../dateFormats";
import FormControl from "@material-ui/core/FormControl";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import MovementStore from "../../../stores/MovementStore";
import StatisticsStore from "../../../stores/StatisticsStore";
import FilterStore from "../../../stores/FilterStore";
import {RouteComponentProps} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, Theme} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";

interface IProps {
    route: RouteComponentProps;
}

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase);

const PeriodPicker = (props: IProps) => {

    const filterStore = useContext(FilterStore);
    const movementStore = useContext(MovementStore);
    const statisticsStore = useContext(StatisticsStore);

    const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        filterStore.setSelectedPeriod(event.target.value as string);
        movementStore.resetMovements();
        statisticsStore.resetStatistics();

        return props.route.history.push(`${props.route.match.path}?period=${event.target.value as string}`);
    };

    const startDate = subMonths(new Date(), 12);
    const endDate = addMonths(new Date(), 2);
    const datesInterval = eachMonthOfInterval({
        start: startDate,
        end: endDate,
    });

    return (
        <FormControl fullWidth>
            <NativeSelect
                id="period"
                value={filterStore.selectedPeriod}
                onChange={handlePeriodChange}
                input={<BootstrapInput/>}
            >
                <option key="all" value="all">Всичко</option>
                {datesInterval.map((date: Date) => {
                    return <option key={`month-option-${date.getMonth()}-${date.getFullYear()}`}
                                   value={format(date, FILTER_DATE_FORMAT)}>
                        {format(date, PERIOD_DISPLAY_DATE_FORMAT)}
                    </option>;
                })}
            </NativeSelect>
        </FormControl>
    );
};

export default observer(PeriodPicker);

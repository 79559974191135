import React, {useContext} from "react";
import {BrowserView, MobileView} from 'react-device-detect';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {BrowserRouter as Router, Route, Switch, withRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import SnackbarStore from "./stores/SnackbarStore";
import WebSettingsPage from "./pages/web/WebSettingsPage";
import MobileSettingsPage from "./pages/mobile/MobileSettingsPage";
import WebStatisticsPage from "./pages/web/WebStatisticsPage";
import MobileStatisticsPage from "./pages/mobile/MobileStatisticsPage";
import WebHomePage from "./pages/web/WebHomePage";
import MobileHomePage from "./pages/mobile/MobileHomePage";

export function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {

    const snackbarStore = useContext(SnackbarStore);

    const handleCloseSnackbar = () => {
        snackbarStore.clearSnackbar();
    };

    return (
        <Router>
            <Switch>
                <Route path="/settings" render={route => {
                    return (
                        <>
                            <BrowserView><WebSettingsPage route={route}/></BrowserView>
                            <MobileView><MobileSettingsPage route={route}/></MobileView>
                        </>
                    );
                }}/>
                <Route path="/statistics" render={route => {
                    return (
                        <>
                            <BrowserView><WebStatisticsPage route={route}/></BrowserView>
                            <MobileView><MobileStatisticsPage route={route}/></MobileView>
                        </>
                    );
                }}/>
                <Route path="/home" render={route => {
                    return (
                        <>
                            <BrowserView><WebHomePage route={route}/></BrowserView>
                            <MobileView><MobileHomePage route={route}/></MobileView>
                        </>
                    );
                }}/>
                <Route path="/" render={route => {
                    return (
                        <>
                            <BrowserView><WebHomePage route={route}/></BrowserView>
                            <MobileView><MobileHomePage route={route}/></MobileView>
                        </>
                    );
                }}/>
            </Switch>

            {snackbarStore.snackbar && <Snackbar open={true} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarStore.snackbar.status}>
                    {snackbarStore.snackbar.message}
                </Alert>
            </Snackbar>}
        </Router>
    );
}

export default withRouter(observer(App));

import React, {ChangeEvent, ReactNode, useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import format from "date-fns/format";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import PlusOneIcon from "@material-ui/icons/PlusOneRounded";
import {makeStyles, Theme} from "@material-ui/core/styles";
import MovementForm from "../../stores/MovementFormStore";
import {observer} from "mobx-react-lite";
import SnackbarStore from "../../stores/SnackbarStore";
import {ISnackbar} from "../../interfaces/ISnackbsar";
import MovementRadioGroup from "./form/MovementRadioGroup";
import MovementTextField from "./form/MovementTextField";
import MovementTags from "./form/MovementTags";
import TagStore from "../../stores/TagStore";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import MovementStore from "../../stores/MovementStore";
import {SQL_DATE_FORMAT} from "../../dateFormats";
import TextField from "@material-ui/core/TextField";
import {green} from "@material-ui/core/colors";
import EstablishmentStore from "../../stores/EstablishmentStore";
import {IEstablishment} from "../../interfaces/IEstablishment";
import UploadReceipt from "./UploadReceipt";
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import {IconButton} from "@material-ui/core";
import {Delete, DeleteForever, RemoveCircle} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Badge from "@material-ui/core/Badge";
import PreviewReceipt from "./PreviewReceipt";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    setIsOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    smallAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const NewItemForm = (props: IProps) => {
    const classes = useStyles();
    const snackbarStore = useContext(SnackbarStore);
    const movementForm = useContext(MovementForm);
    const movementStore = useContext(MovementStore);
    const tagStore = useContext(TagStore);
    const establishmentStore = useContext(EstablishmentStore);
    const [openUploadPane, setOpenUploadPane] = useState<boolean>(false);
    const [previewReceipt, setPreviewReceipt] = useState<string>('');

    if (tagStore.tags === null) {

        if (!tagStore.isLoading) {

            tagStore.loadTags();
        }
    }

    if (establishmentStore.establishments === null) {

        if (!establishmentStore.isLoading) {

            establishmentStore.loadEstablishments();
        }
    }

    const tagsList = tagStore.tags;

    const loadMovements = () => {
        movementStore.resetMovements();
    };

    const handleSuccessForm = (snackbar: ISnackbar) => {
        snackbarStore.setSnackbar(snackbar);
        loadMovements();
        movementForm.resetForm();
    };

    const handleErrorForm = (snackbar: ISnackbar) => {
        snackbarStore.setSnackbar(snackbar);
    };

    const handleFormSubmitAndContinue = () => {

        movementStore.addMovement({
            type: movementForm.type,
            name: movementForm.name,
            amount: +movementForm.amount,
            movementDate: format(movementForm.movementDate, SQL_DATE_FORMAT),
            note: movementForm.note,
            tagsList: movementForm.tagsListInfo,
            establishment: establishmentStore.establishments?.find(e => movementForm.establishment && e.id === +movementForm.establishment),
            receipt: movementForm.receipt,
        }, handleSuccessForm, handleErrorForm);
    };

    const handleFormSubmit = () => {

        handleFormSubmitAndContinue();
        props.setIsOpen(false);
    };

    const handleCancel = () => {

        props.setIsOpen(false);
    };

    const handleEstablishmentChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: ReactNode) => {

        tagStore.loadTags(undefined, [event.target.value as number]);

        movementForm.setEstablishment(event.target.value as number || null);
    };

    return (

        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullScreen={true}
            open={true}
            aria-modal={true}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Ново движение</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Тип движение</FormLabel>
                            <MovementRadioGroup/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>Касова бележка
                            <IconButton edge="start" color="primary" onClick={() => setOpenUploadPane(true)}>
                                <AddAPhoto/>
                            </IconButton>
                            {movementForm.receipt && (
                                <>
                                    <IconButton onClick={() => movementForm.resetReceipt()} color="secondary">
                                        <DeleteForever/>
                                    </IconButton>

                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{overflow: "auto"}}>
                                        <AvatarGroup max={1000} style={{marginTop: '10px'}}>
                                            {movementForm.receipt.map((receipt: string) => {

                                                return <Badge
                                                    overlap="rectangle"
                                                    badgeContent={<IconButton onClick={() => {
                                                        movementForm.removeReceipt(receipt);
                                                    }}><CancelIcon/></IconButton>}
                                                >
                                                    <Avatar src={receipt} variant="rounded"
                                                            className={classes.largeAvatar}
                                                            onClick={() => setPreviewReceipt(receipt)}/>
                                                </Badge>;
                                            })}
                                        </AvatarGroup>
                                    </Grid>
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MovementTextField type="number" id="amount" name="amount" label="Сума: " required/>
                    </Grid>
                    {establishmentStore.establishments &&
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="establishment">Получател</InputLabel>
                        <Select
                            native
                            fullWidth
                            value={movementForm.getValue("establishment")}
                            onChange={handleEstablishmentChange}
                            label="Получател"
                            inputProps={{
                                name: 'establishment',
                                id: 'establishment',
                            }}
                            style={{width: "100%"}}
                        >
                            <option aria-label="None" value=""/>
                            {establishmentStore.establishments && establishmentStore.establishments.map((establishment: IEstablishment) => {
                                return <option value={establishment.id}>{establishment.name}</option>;
                            })}
                        </Select>
                    </FormControl>}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            type="date"
                            variant="outlined"
                            required={true}
                            fullWidth
                            onChange={(event: React.ChangeEvent<{ value: string }>) => movementForm.modify("movementDate", event.target.value)}
                            value={format(movementForm.movementDate, "yyyy-MM-dd")}
                            name="movementDate"
                            label="Дата: "
                            id="movementDate"
                            autoComplete="off"
                            aria-autocomplete="none"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MovementTextField id="note" name="note" label="Бележка: "/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.heading} component="h1" variant="h5">
                            Похарчени за
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <MovementTags tagsList={tagsList}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-around'}}>
                <Button onClick={handleCancel} color="secondary">
                    {/*Откажи*/}
                    <CancelIcon/>
                </Button>
                <Button onClick={handleFormSubmitAndContinue} color="primary">
                    {/*Създай и продължи*/}
                    <PlusOneIcon color="action"/>
                </Button>
                <Button autoFocus onClick={handleFormSubmit} style={{color: green[500]}}>
                    {/*Създай*/}
                    <CheckIcon/>
                </Button>
            </DialogActions>
            {openUploadPane && <UploadReceipt setOpenUploadPane={setOpenUploadPane}/>}
            {previewReceipt && <PreviewReceipt previewReceipt={previewReceipt} setPreviewReceipt={setPreviewReceipt}/>}
        </Dialog>
    );
};

export default observer(NewItemForm);

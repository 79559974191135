import React from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MobileNavigation from "../../components/mobile/MobileNavigation";
import {Container} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import TagsSettings from "../../components/common/tags/TagsSettings";

interface IProps {
    route: RouteComponentProps;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    '& > *': {
        margin: theme.spacing(0.5),
    },
}));

const WebSettingsPage = (props: IProps) => {

    const classes = useStyles();

    return (<Container component="main">
            <CssBaseline/>
            <div className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{height: 'calc(100vh - 112px)', overflow: 'auto'}}>
                        <>SettingsPage</>
                        <TagsSettings/>
                    </Grid>
                    <MobileNavigation route={props.route}/>
                </Grid>
            </div>
        </Container>
    );
};

export default observer(WebSettingsPage);

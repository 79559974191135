import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import TagStore from "../../../stores/TagStore";
import TagsSettingsList from "./TagsSettingsList";
import TagsSettingsAdd from "./TagSettingsAdd";

const TagsSettings = () => {

    const tagsStore = useContext(TagStore);

    if (tagsStore.tags === null) {

        tagsStore.loadTags();
    }

    return (
        <div>
            <TagsSettingsList/>

            <TagsSettingsAdd/>
        </div>
    );
};

export default observer(TagsSettings);

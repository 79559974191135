import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Grid} from "@material-ui/core";

export enum LoadingTypes {
    CIRCULAR = "circular",
    LINEAR = "linear",
}

export enum LoadingColors {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

interface IProps {
    type?: LoadingTypes.CIRCULAR | LoadingTypes.LINEAR;
    color?: LoadingColors.PRIMARY | LoadingColors.SECONDARY;
}

const Loading = (props: IProps) => {

    if (props.type === LoadingTypes.CIRCULAR) {

        return (
            <Grid container xs={12} alignItems="center" justify="space-around"
                  style={{display: "flex", height: '100%'}}>
                <CircularProgress color={props.color || LoadingColors.SECONDARY}/>
            </Grid>
        );
    } else if (props.type === LoadingTypes.LINEAR) {

        return (
            <Grid container xs={12} alignItems="center" justify="space-around"
                  style={{display: "flex", height: '100%'}}>
                <LinearProgress color={props.color || LoadingColors.SECONDARY} style={{width: '90%'}}/>
            </Grid>
        );
    }

    return (
        <Grid container xs={12} alignItems="center" justify="space-around"
              style={{display: "flex", height: '100%'}}>
            <CircularProgress color={props.color || LoadingColors.SECONDARY}/>
        </Grid>
    );
};

export default Loading;

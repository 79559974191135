import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import TagsList from "../TagsList";
import TagStore from "../../../stores/TagStore";
import MovementStore from "../../../stores/MovementStore";
import Typography from "@material-ui/core/Typography";
import FilterStore from "../../../stores/FilterStore";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FilterForm = () => {

    const tagStore = useContext(TagStore);
    const movementStore = useContext(MovementStore);
    const filterStore = useContext(FilterStore);

    const handleApply = () => {

        filterStore.closeForm();
    };

    const handleResetFilters = () => {

        tagStore.clearSelectedTags();
        movementStore.resetMovements();
        filterStore.clearAppliedFilters();

        filterStore.closeForm();
    };

    const handleCancel = () => {

        filterStore.closeForm();
    };

    const isMobile = true;

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullScreen={isMobile}
            open={true}
            aria-modal={true}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Филтри</DialogTitle>
            <DialogContent dividers>
                <Grid item xs={12} sm={12}>
                    <Typography>Тагове</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TagsList/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Откажи
                </Button>
                <Button autoFocus onClick={handleResetFilters} color="primary">
                    Изчисти филтри
                </Button>
                <Button onClick={handleApply} color="primary">
                    Приложи
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(FilterForm);

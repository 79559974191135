import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {ITag} from "../../../interfaces/ITag";
import {Clear} from "@material-ui/icons";

interface IProps {
    tagsList: ITag[];
    handleFilterTags: (searchQ: string) => void;
    handleEnter: (newTag: ITag) => void;
}

const FilterInput = (props: IProps) => {

    const [qValue, setQValue] = useState<string>('');

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {

        if (event.key.toLowerCase() === "enter") {

            const newTag: ITag[] = props.tagsList.filter((tag: ITag) => tag.name.indexOf(qValue) === 0);

            if (newTag.length > 0) {
                props.handleEnter(newTag[0]);
            }
        } else if (event.key.toLowerCase() === "escape") {

            if (qValue.trim() !== '') {
                resetSearch();
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setQValue(event.target.value);
        props.handleFilterTags(event.target.value);
    };

    const resetSearch = () => {
        setQValue('');
        props.handleFilterTags('');
    };

    return (
        <div style={{position: "relative"}}>
            <TextField
                variant="outlined"
                fullWidth
                label="Тагове"
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                value={qValue}
            />

            {qValue.trim() !== '' && <Clear style={{
                position: "absolute",
                top: "15px",
                right: "10px",
            }} onClick={resetSearch} />}
        </div>
    );
};

export default FilterInput;

import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {api} from "../api";
import {AxiosError, AxiosResponse} from "axios";
import BaseStore from "./BaseStore";
import {IStatisticsRow} from "../interfaces/IStatistics";

class StatisticsStore extends BaseStore {

    private path: string = "statistics/";

    @observable private _statistics: IStatisticsRow[] | null = null;

    constructor() {
        super();

        reaction(() => this._statistics, _ => {});
    }

    @action setStatistics = (statistics: IStatisticsRow[]) => {

        this._statistics = statistics;
    };

    @action resetStatistics = () => {

        console.log('reset statistics...')
        this._statistics = null;
    };

    @action loadStatistics = (selectedPeriod: string) => {

        this.setIsLoading(true);
        api.get(`${this.path}sum-by-tags?period=${selectedPeriod}`)
            .then((res: AxiosResponse<IStatisticsRow[]>) => {

                this.setStatistics(res.data);
                this.setIsLoading(false);
            })
            .catch((err: AxiosError) => {

                console.log(err);
                this.setStatistics([]);
                this.setIsLoading(false);
            });
    };

    @computed get statistics() {

        return this._statistics;
    }
}

export default createContext(new StatisticsStore());

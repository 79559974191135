import React, {useContext} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import {IMovement} from "../../interfaces/IMovement";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import format from 'date-fns/format';
import {FULL_DATE_FORMAT} from "../../dateFormats";
import MovementStore from "../../stores/MovementStore";
import {ITag} from "../../interfaces/ITag";
import {formatMoney} from "../../utils/MoneyFormatter";

interface IProps {
    movement: IMovement;
}

const MovementItemMenu = (props: IProps) => {
    const movementStore = useContext(MovementStore);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDelete = () => {

        handleDialogOpen();
    };

    const handleUpdate = () => {

        handleDialogOpen();
    };

    const handleConfirmedDelete = () => {

        movementStore.removeMovement(props.movement);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="update" key="Редактирай" onClick={handleUpdate}>
                <EditIcon/>
            </IconButton>
            <IconButton aria-label="delete" key="Изтрий" onClick={handleDelete}>
                <DeleteIcon/>
            </IconButton>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>{`Искате да изтриете движение ${props.movement.name}?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Сума: {formatMoney(+props.movement.amount)}<br/>
                        Дата: {format(new Date(props.movement.movementDate), FULL_DATE_FORMAT)}<br/>
                        {props.movement.note && <>Бележка: {props.movement.note}<br/></>}
                        {props.movement.tagsList && props.movement.tagsList.length > 0 && <>
                            Тагнато с: {props.movement.tagsList.map((tag: ITag) => tag.name).join(", ")}<br/>
                        </>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDialogClose} color="primary">
                        Не
                    </Button>
                    <Button onClick={handleConfirmedDelete} color="primary" autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default MovementItemMenu;

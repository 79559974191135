import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

var React = require('react');
var ReactDOM = require('react-dom');

ReactDOM.render(
        <Router>
            <Switch>
                <Route path="/">
                    <App/>
                </Route>
            </Switch>
        </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useContext} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from '@material-ui/core/Slide';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {observer} from "mobx-react-lite";
import {TransitionProps} from "@material-ui/core/transitions";
import {IDeleteItem} from "../../../interfaces/IDeleteItem";
import SnackbarStore from "../../../stores/SnackbarStore";
import EstablishmentStore from "../../../stores/EstablishmentStore";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {

    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    deleteItem: IDeleteItem;
    setDeleteItem: (deleteItem: IDeleteItem | null) => void;
}

const ConfirmDelete = (props: IProps) => {
    const establishmentStore = useContext(EstablishmentStore);
    const snackbarStore = useContext(SnackbarStore);

    const handleCancel = () => {

        props.setDeleteItem(null);
    };

    const handleConfirm = () => {

        props.setDeleteItem(null);
        establishmentStore.deleteEstablishment({
            id: props.deleteItem.id,
            name: props.deleteItem.name,
        }, snackbarStore.setSnackbar)
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={true}
            aria-modal={true}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">Потвърждаване на изтриване</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography>
                            Сигурни ли сте, че искате да изтриете {props.deleteItem.type} {props.deleteItem.name}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(ConfirmDelete);

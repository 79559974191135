import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {green, red} from "@material-ui/core/colors";
import AddRounded from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import format from "date-fns/format";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import MovementItemMenu from "./MovementItemMenu";
import {IMovement} from "../../interfaces/IMovement";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FULL_DATE_FORMAT} from "../../dateFormats";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TagsList from "../common/TagsList";
import {observer} from "mobx-react-lite";
import {formatMoney} from "../../utils/MoneyFormatter";
import {API_URL} from "../../api";

interface IProps {
    movement: IMovement;
}

const MovementListItem = (props: IProps) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <React.Fragment>
            <ListItem button alignItems="flex-start" onClick={handleDialogOpen}>
                <ListItemAvatar>
                    <Avatar variant='circle' style={{
                        backgroundColor: 'transparent',
                        border: `solid 1px ${props.movement.amount > 0 ? green[500] : red[500]}`
                    }}>
                        {props.movement.amount > 0 &&
                        <AddRounded style={{color: green[500]}}/>}
                        {props.movement.amount < 0 &&
                        <RemoveRoundedIcon style={{color: red[500]}}/>}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={formatMoney(+props.movement.amount)}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                {props.movement.name}
                                <br/>
                            </Typography>
                            {props.movement.establishment && <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                Получател: {props.movement.establishment.name}
                                <br/>
                            </Typography>}
                            <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
                                {format(new Date(props.movement.movementDate), FULL_DATE_FORMAT)}
                                <br/>
                            </Typography>
                            {` ${props.movement.note || ''}`}
                            <TagsList tagsList={props.movement.tagsList}/>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    <MovementItemMenu movement={props.movement}/>
                </ListItemSecondaryAction>
            </ListItem>

            {dialogOpen && <Dialog
                open={true}
                onClose={handleDialogClose}
            >
                <DialogTitle>{`Детайли за движение ${props.movement.name}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.movement.name && <>Име: {props.movement.name}<br/></>}
                        Сума: {formatMoney(+props.movement.amount)}<br/>
                        Дата: {format(new Date(props.movement.movementDate), FULL_DATE_FORMAT)}<br/>
                        {props.movement.establishment &&
                        <React.Fragment>Получател: {props.movement.establishment.name}<br/></React.Fragment>}
                        {props.movement.note && <React.Fragment>Бележка: {props.movement.note}<br/></React.Fragment>}
                        {props.movement.tagsList.length > 0 &&
                        <React.Fragment>
                            Тагове: <TagsList tagsList={props.movement.tagsList}/>
                            <br/>
                        </React.Fragment>}
                        {props.movement.receipt &&
                        <img src={`${API_URL}receipt/${props.movement.receipt}/load-img`}/>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDialogClose} color="primary">
                        ОК
                    </Button>
                </DialogActions>
            </Dialog>}
        </React.Fragment>
    );
};

export default observer(MovementListItem);

import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterStore from "../../../stores/FilterStore";
import {RouteComponentProps} from "react-router-dom";
import PeriodPicker from "./PeriodPicker";

interface IProps {
    route: RouteComponentProps;
    periodOnly?: boolean;
}

const FiltersContainer = (props: IProps) => {

    const filterStore = useContext(FilterStore);

    const filtersCount: number = filterStore.appliedFilters?.length || 0;

    if (props.periodOnly) {

        return (
            <Grid item sm={12} md={12} lg={12} xs={12}>
                <PeriodPicker route={props.route}/>
            </Grid>
        );
    }

    return (
        <>
            <Grid item sm={11} md={11} lg={11} xs={9}>
                <PeriodPicker route={props.route}/>
            </Grid>
            <Grid item sm={1} md={1} lg={1} xs={1}>
                <FormControl>
                    <Button onClick={() => filterStore.openForm()}>
                        {/*<Badge color="secondary" variant="dot"*/}
                        <Badge color="secondary" variant="standard" badgeContent={filtersCount}
                               invisible={filtersCount <= 0}>
                            <FilterListIcon/>
                        </Badge>
                    </Button>
                </FormControl>
            </Grid>
        </>
    );
};

export default observer(FiltersContainer);

import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {IEstablishment, INewEstablishment} from "../interfaces/IEstablishment";
import {api} from "../api";
import {AxiosError, AxiosResponse} from "axios";
import {ISnackbar} from "../interfaces/ISnackbsar";
import {ITag} from "../interfaces/ITag";

class EstablishmentStore {

    private path: string = "establishments/";

    @observable private _selectedEstablishment: IEstablishment | null = null;
    @observable private _establishments: IEstablishment[] | null = null;
    @observable private _establishmentTags: ITag[] | null = null;
    @observable private _isLoading: boolean = false;

    constructor() {
        reaction(() => this._selectedEstablishment, _ => {});
        reaction(() => this._establishments, _ => {});
        reaction(() => this._establishmentTags, _ => {});
        reaction(() => this._isLoading, _ => {});
    }

    @action setSelectedEstablishment = (selectedEstablishment: IEstablishment) => {
        this._selectedEstablishment = selectedEstablishment;
    }

    @action selectEstablishment = (establishment: IEstablishment) => {

        if (this._selectedEstablishment === null) {
            this.setSelectedEstablishment(establishment);

            return;
        }

        this.setSelectedEstablishment(
            establishment,
        );
    };

    @action clearSelectedEstablishments = () => {
        this._selectedEstablishment = null;
    }

    @computed get selectedEstablishment() {
        return this._selectedEstablishment;
    }

    @action setEstablishments = (establishments: IEstablishment[]) => {

        this._establishments = establishments;
    };

    @action loadEstablishments = (establishmentNameSearch?: string | undefined) => {

        let url = this.path;

        if (establishmentNameSearch !== undefined && establishmentNameSearch.trim() !== '') {

            url += `?q=${establishmentNameSearch.trim()}`;
        }

        this.setIsLoading(true);
        api.get(url)
            .then((res: AxiosResponse<IEstablishment[]>) => {

                this.setEstablishments(res.data);
                this.setIsLoading(false);
            })
            .catch((err: AxiosError) => {

                console.log(err);
                this.setEstablishments([]);
                this.setIsLoading(false);
            });
    };

    @action createEstablishment = (newEstablishment: INewEstablishment, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.post(this.path, {
            ...newEstablishment,
            establishmentTags: this.establishmentTags,
        })
            .then((res: AxiosResponse<IEstablishment>) => {

                // todo could optimize by adding the establishment rather than load up all of them again
                this.loadEstablishments();
                setSnackbar({
                    status: 'success',
                    message: 'Заведението е създаден успешно',
                });

            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при създаването на заведението',
                });
            });
    };

    @action updateEstablishment = (updatedEstablishment: IEstablishment, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.patch(`${this.path}${updatedEstablishment.id}`, {
            ...updatedEstablishment,
            establishmentTags: this.establishmentTags,
        })
            .then((res: AxiosResponse<IEstablishment>) => {

                // todo could optimize by updating the establishment rather than load up all of them again
                this.loadEstablishments();
                setSnackbar({
                    status: 'success',
                    message: 'Заведението е обновен успешно',
                });
            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при обновяване на заведението',
                });
            })
    };

    @action deleteEstablishment = (establishment: IEstablishment, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.delete(`${this.path}${establishment.id}`)
            .then((res: AxiosResponse<boolean>) => {

                // todo could optimize by remove the establishment rather than load up all of them again
                this.loadEstablishments();
                setSnackbar({
                    status: 'success',
                    message: 'Заведението е изтрит успешно',
                });
            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при изтриване на заведението',
                });
            });
    };

    @computed get establishments() {

        return this._establishments;
    }

    @action setIsLoading = (isLoading: boolean) => {

        this._isLoading = isLoading;
    };

    @computed get isLoading() {

        return this._isLoading;
    }

    @action setEstablishmentTags = (establishments: ITag[]) => {

        this._establishmentTags = establishments;
    };

    @action resetEstablishmentTags = () => {
        this._establishmentTags = null;
    }

    @action loadEstablishmentTags = (establishmentId: number, tagNameSearch?: string | undefined) => {

        let url: string = `${this.path}${establishmentId}/tags`;

        if (tagNameSearch !== undefined && tagNameSearch.trim() !== '') {

            url += `&q=${tagNameSearch.trim()}`;
        }

        this.setIsLoading(true);
        api.get(url)
            .then((res: AxiosResponse<IEstablishment[]>) => {

                this.setEstablishmentTags(res.data);
                this.setIsLoading(false);
            })
            .catch((err: AxiosError) => {

                console.log(err);
                this.setEstablishmentTags([]);
                this.setIsLoading(false);
            });
    };

    @computed get establishmentTags() {

        return this._establishmentTags;
    }
}

export default createContext(new EstablishmentStore());

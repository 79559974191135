import React from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

interface IProps {
    message?: string;
}

const EmptyList = (props: IProps) => {

    return (
        <Grid container xs={12} alignItems="center" justify="space-around" style={{display: "flex", height: '100%'}}>
            <Grid item>
                <Typography>{props.message || 'No data'}</Typography>
            </Grid>
        </Grid>
    );
};

export default EmptyList;

import React, {Ref, useContext, useRef} from 'react';
import Webcam from "react-webcam";
import {Grid} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import MovementFormStore from "../../stores/MovementFormStore";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import PlusOneIcon from "@material-ui/icons/PlusOneRounded";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

interface IProps {
    setOpenUploadPane: (openUploadPane: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        webCamContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff',
            zIndex: 9999,
        },
        actionContainer: {
            position: 'absolute',
            bottom: theme.spacing(5),
            right: theme.spacing(2),
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(5),
            right: theme.spacing(2),
        },
        fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
        // actionIcons: {
        //     fontSize: theme.typography.fontSize + 10,
        // },
    }),
);

const UploadReceipt = (props: IProps) => {
    const classes = useStyles();

    const movementFormStore = useContext(MovementFormStore);

    const webcamRef: Ref<any> | null = useRef(null);

    const handleCapture = React.useCallback(
        () => {

            if (webcamRef !== null && webcamRef.current !== null) {

                const imageSrc: string | null = webcamRef.current.getScreenshot();

                if (imageSrc !== null) {

                    movementFormStore.addReceipt(imageSrc);
                    props.setOpenUploadPane(false);
                }
            }
        },
        [webcamRef]
    );

    const handleCancel = () => {

        props.setOpenUploadPane(false);
    };

    const videoConstraints = {
        // facingMode: { exact: "environment" }
    };

    const WebcamComponent = () => <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        videoConstraints={videoConstraints}
    />;

    return (
        <Grid container className={classes.webCamContainer}>
            <WebcamComponent/>
            <Grid item className={classes.actionContainer}>
                <IconButton onClick={handleCancel} color="secondary" >
                    <CancelIcon fontSize="large" />
                </IconButton>
                <Button autoFocus onClick={handleCapture} style={{color: green[500]}} >
                    <CheckIcon fontSize="large" />
                </Button>
            </Grid>
        </Grid>
    );
};

export default observer(UploadReceipt);

import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import MovementFormStore from "../../../stores/MovementFormStore";

const MovementRadioGroup = () => {
    const movementFormStore = useContext(MovementFormStore);

    return (
        <RadioGroup aria-label="type"
                    onChange={(event: React.ChangeEvent<{ value: string }>) => movementFormStore.setType(event.target.value)}
                    value={movementFormStore.type}
                    name="type">
            <FormControlLabel
                control={<Radio/>}
                value="income"
                label="Приход"
            />
            <FormControlLabel
                control={<Radio/>}
                value="spending"
                label="Разход"
            />
        </RadioGroup>
    );
};

export default observer(MovementRadioGroup);

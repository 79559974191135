import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import ConfirmDelete from "./ConfirmDelete";
import {IDeleteItem} from "../../../interfaces/IDeleteItem";
import {IEditItem} from "../../../interfaces/IEditItem";
import EstablishmentsSettingsEdit from "./EstablishmentsSettingsEdit";
import EstablishmentStore from "../../../stores/EstablishmentStore";

const EstablishmentsSettingsList = () => {

    const establishmentsStore = useContext(EstablishmentStore);
    const [deleteItem, setDeleteItem] = useState<IDeleteItem | null>(null);
    const [editItem, setEditItem] = useState<IEditItem | null>(null);

    if (establishmentsStore.establishments === null) {

        establishmentsStore.loadEstablishments();
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Получател</TableCell>
                            <TableCell align="right">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {establishmentsStore.establishments && establishmentsStore.establishments.map((establishment) => (
                            <TableRow key={establishment.id}>
                                <TableCell component="th" scope="row">
                                    {establishment.name}
                                </TableCell>
                                <TableCell align="right">
                                    <Button onClick={() => setEditItem({
                                        type: 'Получател',
                                        fieldName: "name",
                                        fieldLabel: "Име: ",
                                        id: establishment.id,
                                        name: establishment.name,
                                        linkedWith: {
                                            type: "Движения",
                                            elements: [],
                                        }
                                    })}>
                                        <EditIcon/>
                                    </Button>
                                    <Button onClick={() => setDeleteItem({
                                        type: 'Получател',
                                        id: establishment.id,
                                        name: establishment.name,
                                    })}>
                                        <DeleteIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {deleteItem && <ConfirmDelete deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>}
            {editItem && <EstablishmentsSettingsEdit editItem={editItem} setEditItem={setEditItem}/>}
        </div>
    );
};

export default observer(EstablishmentsSettingsList);

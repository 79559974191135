import {action, computed, observable, reaction} from "mobx";

class BaseStore {
    @observable private _isLoading: boolean = false;

    constructor() {
        reaction(() => this._isLoading, _ => {});
    }

    @action setIsLoading = (isLoading: boolean) => {

        this._isLoading = isLoading;
    };

    @computed get isLoading() {

        return this._isLoading;
    }
}

export default BaseStore;

import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {IMovement} from "../interfaces/IMovement";
import {api} from "../api";
import {ITag} from "../interfaces/ITag";
import {AxiosError, AxiosResponse} from "axios";
import {ISnackbar} from "../interfaces/ISnackbsar";

class MovementStore {

    constructor() {
        reaction(() => this.movements, _ => {});
        reaction(() => this._isLoading, _ => {});
    }

    @observable private _isLoading: boolean = false;

    @observable private _movements: IMovement[] | null = null;

    @action setMovements = (movements: IMovement[]) => {

        this._movements = movements;
    };

    @action resetMovements = () => {

        this._movements = null;
    };

    @action loadMovements = (selectedPeriod: string, selectedTags: ITag[] | null) => {
        let apiUrl = `movement/?period=${selectedPeriod}`;
        if (selectedTags) {
            apiUrl += `&tags=${selectedTags.map((selectedTag: ITag) => selectedTag.id).join(",")}`;
        }

        api.get(apiUrl)
            .then((res: AxiosResponse<IMovement[]>) => {

                if (res.data) {

                    this.setMovements(res.data);
                }
            })
            .catch((error: AxiosError) => {
                console.log(error.message);
                this.setMovements([]);
            });
    };

    @action addMovement = (movement: IMovement, successCallback: (snackbar: ISnackbar) => void, errorCallback: (snackbar: ISnackbar) => void) => {

        api.post("movement/", movement)
            .then((res: AxiosResponse) => {
                successCallback({
                    status: 'success',
                    message: 'Движението е добавено успешно',
                });
                // this.resetForm();
            })
            .catch((error: AxiosError) => {
                errorCallback({
                    status: 'error',
                    message: `Възникна грешка (${error.message})`,
                });
            });
    };

    @action removeMovement = (movement: IMovement) => {

        api.delete(`movement/${movement.id}`)
            .then((res: AxiosResponse<boolean>) => {

                this.resetMovements();
            })
            .catch((err: AxiosError) => {

                console.log(err);
            })
    };

    @action removeMovementTag = (movement: IMovement, removeTag: ITag) => {

        api.patch(`movement/${movement.id}`, {
            tagsList: movement.tagsList.filter((tag: ITag) => tag.id !== removeTag.id)
        })
            .catch((res: AxiosResponse<ITag>) => {

                this.resetMovements();
            })
            .catch((err: AxiosError) => {

                console.log(err);
            });
    };

    @computed get movements() {
        return this._movements;
    }

    @computed get info() {
        return {
            total: this.movements?.length,
        };
    }

    @action setIsLoading = (isLoading: boolean) => {

        this._isLoading = isLoading;
    };

    @computed get isLoading() {

        return this._isLoading;
    }
}

export default createContext(new MovementStore());

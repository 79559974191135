import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {IMovement} from "../../interfaces/IMovement";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MovementStore from "../../stores/MovementStore";
import {format} from "date-fns";
import {PERIOD_DISPLAY_DATE_FORMAT} from "../../dateFormats";
import MinusDollarSign from "../../assets/minus-dollar-sign_nlartb.png";
import TagsList from "../common/TagsList";
import {useLocation} from "react-router";
import FilterStore from "../../stores/FilterStore";
import Loading, {LoadingTypes} from "../common/Loading";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: '45%',
            // height: 450,
        },
        gritItemsContainer: {
            height: 450,
            overflow: 'auto',
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
    }),
);

const MovementTimeline = () => {

    const classes = useStyles();

    const filterStore = useContext(FilterStore);
    const movementStore = useContext(MovementStore);
    const movementsList = movementStore.movements;

    const {search} = useLocation();
    const period: string | null = new URLSearchParams(search).get("period");

    if (period !== null && period !== filterStore.selectedPeriod && period !== undefined) {

        filterStore.setSelectedPeriod(period);
    }

    if (movementStore.movements === null) {

        if (!movementStore.isLoading) {

            movementStore.loadMovements(filterStore.selectedPeriod, null);
        }

        return <Loading/>;
    }

    return (
        <div className={classes.root}>
            <GridList cellHeight={180} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{height: 'auto'}}>
                    <ListSubheader
                        component="div">{format(new Date(filterStore.selectedPeriod), PERIOD_DISPLAY_DATE_FORMAT)}</ListSubheader>
                </GridListTile>
                {movementsList && movementsList.map((movement: IMovement) => {

                    if (movement.amount > 0) {

                        return (
                            <GridListTile style={{width: '100%'}} key={movement.id}>
                                {/*<img src={PlusDollarSign} alt={movement.name} style={{opacity: .1}}/>*/}
                                <GridListTileBar
                                    title={<div>{movement.name}{+movement.amount}</div>}
                                    subtitle={<span><TagsList/></span>}
                                    actionIcon={
                                        <IconButton aria-label={`info about ${movement.name}`}
                                                    className={classes.icon}>
                                            <InfoIcon/>
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        );
                    }
                })}

            </GridList>
            <GridList cellHeight={180} className={classes.gridList}>
                <GridListTile key="Subheader" cols={2} style={{height: 'auto'}}>
                    <ListSubheader
                        component="div">{format(new Date(filterStore.selectedPeriod), PERIOD_DISPLAY_DATE_FORMAT)}</ListSubheader>
                </GridListTile>
                {movementsList && movementsList.map((movement: IMovement) => {

                    if (movement.amount < 0) {

                        return (
                            <GridListTile key={movement.id}>
                                <img src={MinusDollarSign} alt={movement.name} style={{opacity: .1}}/>
                                <GridListTileBar
                                    title={movement.name}
                                    subtitle={<span>{movement.amount}</span>}
                                    actionIcon={
                                        <IconButton aria-label={`info about ${movement.name}`}
                                                    className={classes.icon}>
                                            <InfoIcon/>
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        );
                    }
                })}
            </GridList>
        </div>
    );
};

export default observer(MovementTimeline);

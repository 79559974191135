import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {ITag} from "../../../interfaces/ITag";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FilterInput from "../form/FilterInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import EstablishmentStore from "../../../stores/EstablishmentStore";
import {IEstablishment} from "../../../interfaces/IEstablishment";
import TagStore from "../../../stores/TagStore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

interface IProps {

    establishment?: IEstablishment;
}

const EstablishmentTagList = (props: IProps) => {

    const classes = useStyles();

    const establishmentStore = useContext(EstablishmentStore);
    const tagStore = useContext(TagStore);

    const tagsList: ITag[] | null = tagStore.tags;
    const establishmentTagsList: ITag[] | null = establishmentStore.establishmentTags;

    if (props.establishment?.id !== establishmentStore.selectedEstablishment?.id) {

        if (props.establishment) {

            establishmentStore.selectEstablishment(props.establishment);
            establishmentStore.resetEstablishmentTags();

        } else {

            establishmentStore.clearSelectedEstablishments();
            establishmentStore.resetEstablishmentTags();
        }
    }

    if (tagsList === null) {

        if (!tagStore.isLoading) {

            tagStore.loadTags();
        }
        return <Loading/>;
    }

    if (establishmentTagsList === null && !props.establishment) {

        establishmentStore.setEstablishmentTags([]);
    }

    if (establishmentTagsList === null && props.establishment) {

        if (!establishmentStore.isLoading) {

            establishmentStore.loadEstablishmentTags(props.establishment.id);
        }
        return <Loading/>;

    }

    const handleFilterTags = (searchQ: string) => {

        tagStore.loadTags(searchQ);
    };

    const handleEnter = (newTag: ITag) => {

        selectTag(newTag);
    };

    const handleChangeEstablishmentTagsList = (event: React.ChangeEvent<{ value: string }>, checked: boolean) => {

        const eventTag: ITag[] | undefined = tagStore.tags?.filter((tag: ITag) => tag.id === +event.target.value);

        if (eventTag === undefined) {
            return;
        }

        selectTag(eventTag[0]);
    };

    const selectTag = (newTag: ITag) => {

        let newList: ITag[] | null = establishmentStore.establishmentTags;

        if (newList === null) {

            newList = [];
        }

        const index: number = newList.findIndex((tag: ITag) => tag.id === newTag.id);

        if (index === -1) {

            newList.push(newTag);

        } else {

            newList.splice(index, 1)
        }

        establishmentStore.setEstablishmentTags(newList);
    };

    return (
        <FormControl component="fieldset"
                     className={classes.formControl}>
            <FormLabel component="legend">
                {tagsList && <FilterInput tagsList={tagsList}
                                          handleFilterTags={handleFilterTags}
                                          handleEnter={handleEnter}/>}
            </FormLabel>
            <FormGroup>
                {tagsList && tagsList.map((tag: ITag) => {

                    return (
                        <FormControlLabel
                            key={`tag-${tag.id}`}
                            control={<Checkbox
                                checked={establishmentTagsList?.find(t => t.id === tag.id) !== undefined}
                                onChange={handleChangeEstablishmentTagsList}
                                name="tagsList" value={tag.id}/>}
                            label={tag.name}
                        />
                    )
                })}
            </FormGroup>
            <FormHelperText></FormHelperText>
        </FormControl>
    );
};

export default observer(EstablishmentTagList);

import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {api} from "../api";
import {AxiosError, AxiosResponse} from "axios";
import {ISnackbar} from "../interfaces/ISnackbsar";
import {ITag} from "../interfaces/ITag";

class MovementFormStore {

    @observable private _type: string = 'spending';
    @observable private _amount: string = '';
    @observable private _name: string = '';
    @observable private _establishment: number | null = null;
    @observable private _note: string = '';
    @observable private _movementDate: Date = new Date();
    @observable private _receipt: string[] | null = null;
    @observable expandedSpentFor: boolean = false;
    @observable movementTagsList: ITag[];

    constructor() {
        this.movementTagsList = [];
        reaction(() => {
            return {
                type: this._type,
                amount: this._amount,
                name: this._name,
                establishment: this._establishment,
                note: this._note,
                movementDate: this._movementDate,
                receipt: this._receipt,
            }
        }, _ => {});
    }

    @action setType = (type: string) => {
        this._type = type;
    };

    @action setAmount = (amount: string) => {
        this._amount = amount;
    };

    @action setName = (name: string) => {
        this._name = name;
    };

    @action setEstablishment = (establishment: number | null) => {
        this._establishment = establishment;
    };

    @action setNote = (note: string) => {
        this._note = note;
    };

    @action setMovementDate = (movementDate: Date) => {
        this._movementDate = movementDate;
    };

    @action setExpandedSpentFor = (expandedSpentFor: boolean) => {
        this.expandedSpentFor = expandedSpentFor;
    };

    @action setMovementTagsList = (movementTagsList: ITag[]) => {
        this.movementTagsList = movementTagsList;
    };

    @action setReceipt = (receipt: string[]) => {
        this._receipt = receipt;
    };

    @action addReceipt = (receipt: string) => {

        if (this._receipt === null) {

            this._receipt = [];
        }
        this._receipt.push(receipt);
    };

    @action removeReceipt = (receipt: string) => {

        const index: number | undefined = this._receipt?.findIndex((r: string) => r === receipt);

        if (this._receipt === null || index === undefined || index === -1) {

            return;
        }
        this._receipt.splice(index, 1);
        if (this._receipt.length === 0) {

            this.resetReceipt();
        }
    };

    @action resetReceipt = () => {
        this._receipt = null;
    };

    @action storeMovement = (successCallback: (snackbar: ISnackbar) => void, errorCallback: (snackbar: ISnackbar) => void) => {
        api.post("movement/", {
            type: this._type,
            name: this._name,
            establishment: this._establishment,
            amount: +this._amount,
            movementDate: this._movementDate,
            note: this._note,
            tagsList: this.tagsListInfo,
            receipt: this.receipt,
        })
            .then((res: AxiosResponse) => {
                successCallback({
                    status: 'success',
                    message: 'Движението е добавено успешно',
                });
                this.resetForm();
            })
            .catch((error: AxiosError) => {
                errorCallback({
                    status: 'error',
                    message: `Възникна грешка (${error.message})`,
                });
            });
    };

    @action modify = (name: string, value: string) => {
        if (name === 'type') {
            this.setType(value);
        } else if (name === 'name') {
            this.setName(value);
        } else if (name === 'establishment') {
            this.setEstablishment(+value);
        } else if (name === 'amount') {
            this.setAmount(value);
        } else if (name === 'note') {
            this.setNote(value);
        } else if (name === 'movementDate') {
            this.setMovementDate(new Date(value));
        } else {
            throw new Error("Invalid input parameters");
        }
    };

    @action resetForm = () => {
        this.setType('spending');
        this.setName('');
        this.setEstablishment(null);
        this.setAmount('');
        this.setNote('');
        this.setMovementDate(new Date());
        this.setReceipt([]);
        this.setMovementTagsList([]);
    };

    getValue(name: string) {
        if (name === 'type') {
            return this.type;
        } else if (name === 'name') {
            return this.name;
        } else if (name === 'establishment') {
            return this.establishment;
        } else if (name === 'amount') {
            return this.amount;
        } else if (name === 'note') {
            return this.note;
        } else if (name === 'movementDate') {
            return this.movementDate;
        }  else if (name === 'receipt') {
            return this.receipt;
        } else {
            throw new Error("Invalid input parameters");
        }
    }

    @computed get expandedInfo() {
        return this.expandedSpentFor;
    }


    @computed get type(): string {
        return this._type;
    }

    @computed get amount(): string {
        return this._amount;
    }

    @computed get name(): string {
        return this._name;
    }

    @computed get establishment(): number | null {
        return this._establishment;
    }

    @computed get note(): string {
        return this._note;
    }

    @computed get movementDate(): Date {
        return this._movementDate;
    }

    @computed get receipt(): string[] | null {
        return this._receipt;
    }

    @computed get tagsListInfo() {
        return this.movementTagsList;
    }
}

export default createContext(new MovementFormStore());

import React, {useContext} from "react";
import {ITag} from "../../interfaces/ITag";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import {observer} from "mobx-react-lite";
import TagStore from "../../stores/TagStore";
import MovementStore from "../../stores/MovementStore";
import FilterStore from "../../stores/FilterStore";
import Loading from "./Loading";

interface IProps {
    onClick?: (tagId: number) => void;
    handleChipDelete?: (tagId: number) => void;
    tagsList?: ITag[];
}

const useStyles = makeStyles((theme) => ({
    chipItem: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

const TagsList = (props: IProps) => {

    const filterStore = useContext(FilterStore);
    const tagStore = useContext(TagStore);
    const movementStore = useContext(MovementStore);

    const classes = useStyles();

    const handleChipClick = (tag: ITag) => {

        tagStore.selectTag(tag);
        movementStore.resetMovements();
        filterStore.applyFilter({
            type: 'tag',
            id: `tag-${tag.id}`,
            value: tag,
        });
    };

    const handleChipDelete = (tag: ITag) => {

        tagStore.deselectTag(tag);
        movementStore.resetMovements();
        filterStore.removeFilter({
            type: 'tag',
            id: `tag-${tag.id}`,
            value: tag,
        });
    };

    if (tagStore.tags === null) {

        if (!tagStore.isLoading) {

            tagStore.loadTags();
        }
        return <Loading/>;
    }

    const tagsList: ITag[] = props.tagsList ? props.tagsList : tagStore.tags;

    return (
        <React.Fragment>
            {tagsList.map((tag: ITag) => {
                return <Chip
                    key={`tag-${tag.id}`}
                    label={tag.name}
                    className={classes.chipItem}
                    clickable={true}
                    onClick={() => handleChipClick(tag)}
                    onDelete={(tagStore.selectedTags && tagStore.selectedTags?.findIndex((value: ITag) => value.id === tag.id) !== -1) ? () => handleChipDelete(tag) : undefined}
                />
            })}
        </React.Fragment>
    );
};

export default observer(TagsList);

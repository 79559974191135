import React, {useContext} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {IStatisticsRow} from "../../../interfaces/IStatistics";
import StatisticsListItem from "./StatisticsListItem";
import TableContainer from "@material-ui/core/TableContainer";
import StatisticsStore from "../../../stores/StatisticsStore";
import FilterStore from "../../../stores/FilterStore";
import Loading from "../Loading";
import EmptyList from "../EmptyList";
import {format} from "date-fns";
import {PERIOD_DISPLAY_DATE_FORMAT} from "../../../dateFormats";
import {observer} from "mobx-react-lite";
import withStyles from "@material-ui/core/styles/withStyles";
import {createStyles, Theme} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {useLocation} from "react-router";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

const Statistics = () => {

    const statisticsStore = useContext(StatisticsStore);
    const filterStore = useContext(FilterStore);

    const {search} = useLocation();
    const period: string | null = new URLSearchParams(search).get("period");

    if (period !== null && period !== filterStore.selectedPeriod && period !== undefined) {

        filterStore.setSelectedPeriod(period);
    }

    if (statisticsStore.statistics === null) {

        if (!statisticsStore.isLoading) {

            statisticsStore.loadStatistics(filterStore.selectedPeriod);
        }
        return <Loading/>;
    }

    if (statisticsStore.statistics.length === 0) {

        return <EmptyList
            message={`No statistics for ${format(new Date(filterStore.selectedPeriod), PERIOD_DISPLAY_DATE_FORMAT)}`}/>;
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Таг</StyledTableCell>
                        <StyledTableCell align="right">Сума</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(statisticsStore.statistics).map(([tagId, row]: [string, IStatisticsRow]) => (
                        <StatisticsListItem row={row}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default observer(Statistics);

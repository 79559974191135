import React from 'react';
import {RouteComponentProps} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MobileNavigation from "../../components/mobile/MobileNavigation";
import {makeStyles} from "@material-ui/core/styles";
import Statistics from "../../components/common/statistics/Statistics";
import FiltersContainer from "../../components/common/filters/FiltersContainer";

interface IProps {
    route: RouteComponentProps;
}

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        height: "100vh",
    },
    topPanel: {
        flex: 1,
        borderTop: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
    bottomPanel: {
        flex: 10,
        overflow: "auto",
    },
    middlePanel: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        borderBottom: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
}));

const MobileStatisticsPage = (props: IProps) => {

    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item xs={12} className={classes.topPanel}>
                <MobileNavigation route={props.route}/>
            </Grid>
            <Grid item xs={12} className={classes.middlePanel}>
                <FiltersContainer route={props.route} periodOnly={true}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomPanel}>
                <Statistics/>
            </Grid>
        </Grid>
    );
};

export default MobileStatisticsPage;

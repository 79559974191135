import React, {useContext} from 'react';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsIcon from "@material-ui/icons/Settings";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NavigationStore from "../../stores/NavigationStore";
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router-dom";
import FilterStore from "../../stores/FilterStore";
import StatisticsStore from "../../stores/StatisticsStore";

interface IProps {
    route: RouteComponentProps;
}

const MobileNavigation = (props: IProps) => {
    const navigationStore = useContext(NavigationStore);
    const filterStore = useContext(FilterStore);
    const statisticsStore = useContext(StatisticsStore);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {// setValue(newValue);

        if (newValue === navigationStore.PAGE[1].key) {

            statisticsStore.resetStatistics();
        }

        props.route.history.push(`/${newValue}?period=${filterStore.selectedPeriod}`);
    };

    let activePage: string = props.route.match.url.replace("/", "");

    if (activePage === '') {

        activePage = 'home';
    }

    return (
        <BottomNavigation
            style={{height: 'auto'}}
            value={activePage}
            onChange={handleChange}>
            <BottomNavigationAction label="Начало" value={navigationStore.PAGE[0].key} icon={<HomeIcon/>}/>
            <BottomNavigationAction label="Статистика" value={navigationStore.PAGE[1].key} icon={<EqualizerIcon/>}/>
            <BottomNavigationAction label="Nearby" value={navigationStore.PAGE[2].key} icon={<LocationOnIcon/>}/>
            <BottomNavigationAction label="Настройки" value={navigationStore.PAGE[3].key} icon={<SettingsIcon/>}/>
        </BottomNavigation>
    );
};

export default observer(MobileNavigation);

import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import TextField from "@material-ui/core/TextField";
import MovementFormStore from "../../../stores/MovementFormStore";

interface IProps {
    id: string;
    name: string;
    label: string;
    required?: boolean;
    type?: string;
    defaultValue?: string;
    value?: string;
}

const MovementTextField = (props: IProps) => {
    const movementFormStore = useContext(MovementFormStore);

    return (
        <TextField
            type={props.type}
            variant="outlined"
            required={props.required || false}
            fullWidth
            onChange={(event: React.ChangeEvent<{ value: string }>) => movementFormStore.modify(props.name, event.target.value)}
            value={movementFormStore.getValue(props.name)}
            name={props.name}
            label={props.label}
            id={props.id}
            // defaultValue={props.defaultValue}
            autoComplete="off"
            aria-autocomplete="none"
        />
    );
};

export default observer(MovementTextField);

import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {IFilter} from "../interfaces/IFilter";
import {format} from "date-fns";
import {FILTER_DATE_FORMAT} from "../dateFormats";

class FilterStore {
    @observable private _appliedFilters: IFilter[] | null = null;
    @observable private _isFormOpen: boolean = false;
    @observable private _selectedPeriod: string;

    constructor() {
        reaction(() => this._appliedFilters, _ => {});
        reaction(() => this._isFormOpen, _ => {});
        reaction(() => this.selectedPeriod, _ => {});
        this._selectedPeriod = format(new Date(), FILTER_DATE_FORMAT);
    }

    @action setAppliedFilters = (appliedFilters: IFilter[]) => {
        this._appliedFilters = appliedFilters;
    }

    @action applyFilter = (filter: IFilter) => {

        if (this._appliedFilters === null) {
            this.setAppliedFilters([filter]);

            return;
        }

        if (this.appliedFilters?.findIndex(value => value.id === filter.id) === -1) {

            this.setAppliedFilters([
                ...this.appliedFilters,
                filter,
            ]);
        }
    };

    @action removeFilter = (filter: IFilter) => {

        const index: number | undefined = this.appliedFilters?.findIndex(value => value.id === filter.id);

        if (index !== undefined && this.appliedFilters !== null && index !== -1) {

            this.appliedFilters.splice(index, 1);
        }
    };

    @action clearAppliedFilters = () => {
        this._appliedFilters = null;
    }

    @computed get appliedFilters() {
        return this._appliedFilters;
    }

    @action setIsFormOpen = (isFormOpen: boolean) => {

        this._isFormOpen = isFormOpen;
    };

    @action closeForm = () => {

        this._isFormOpen = false;
    };

    @action openForm = () => {

        this._isFormOpen = true;
    };

    @computed get isFormOpen() {

        return this._isFormOpen;
    }

    @action setSelectedPeriod = (selectedPeriod: string) => {

        this._selectedPeriod = selectedPeriod;
    };

    @computed get selectedPeriod() {

        return this._selectedPeriod;
    }
}

export default createContext(new FilterStore());

import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {ITag} from "../../../interfaces/ITag";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MovementFormStore from "../../../stores/MovementFormStore";
import {makeStyles} from "@material-ui/core/styles";
import TagStore from "../../../stores/TagStore";
import FilterInput from "./FilterInput";

interface IProps {
    tagsList: ITag[] | null;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const MovementTags = (props: IProps) => {
    const classes = useStyles();
    const movementFormStore = useContext(MovementFormStore);
    const tagStore = useContext(TagStore);

    const handleChangeMovementTagsList = (event: React.ChangeEvent<{ value: string }>, checked: boolean) => {
        const eventTag: ITag[] | undefined = tagStore.tags?.filter((tag: ITag) => tag.id === +event.target.value);

        if (eventTag === undefined) {
            return;
        }

        const newList = [
            ...movementFormStore.tagsListInfo.filter((tag: ITag) => tag.id !== eventTag[0].id),
        ];
        if (checked) {
            newList.push(eventTag[0]);
        }
        movementFormStore.setMovementTagsList(newList);
    };

    const selectTag = (newTag: ITag) => {
        const newList = movementFormStore.tagsListInfo;
        if (newList.findIndex((tag: ITag) => tag.id === newTag.id) === -1) {
            newList.push(newTag);
        }
        movementFormStore.setMovementTagsList(newList);
    };

    const handleFilterTags = (searchQ: string) => {

        tagStore.loadTags(searchQ);
    };

    const handleEnter = (newTag: ITag) => {

        selectTag(newTag);
    };

    return (
        <FormControl component="fieldset"
                     className={classes.formControl}>
            <FormLabel component="legend">
                {props.tagsList &&
                <FilterInput tagsList={props.tagsList} handleFilterTags={handleFilterTags} handleEnter={handleEnter}/>}
            </FormLabel>
            <FormGroup>
                {props.tagsList && props.tagsList.map((tag: ITag) => {
                    return (
                        <FormControlLabel
                            key={`tag-${tag.id}`}
                            control={<Checkbox
                                checked={movementFormStore.tagsListInfo.indexOf(tag) !== -1}
                                onChange={handleChangeMovementTagsList}
                                name="tagsList" value={tag.id}/>}
                            label={tag.name}
                        />
                    )
                })}
            </FormGroup>
            <FormHelperText></FormHelperText>
        </FormControl>
    );
};

export default observer(MovementTags);

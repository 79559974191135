import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {INewTag, ITag} from "../interfaces/ITag";
import {api} from "../api";
import {AxiosError, AxiosResponse} from "axios";
import {ISnackbar} from "../interfaces/ISnackbsar";

class TagStore {
    @observable private _selectedTags: ITag[] | null = null;
    @observable private _tags: ITag[] | null = null;
    @observable private _isLoading: boolean = false;

    constructor() {
        reaction(() => this._selectedTags, _ => {});
        reaction(() => this._tags, _ => {});
        reaction(() => this._isLoading, _ => {});
    }

    @action setSelectedTags = (selectedTags: ITag[]) => {
        this._selectedTags = selectedTags;
    }

    @action selectTag = (tag: ITag) => {

        if (this._selectedTags === null) {
            this.setSelectedTags([tag]);

            return;
        }

        if (this.selectedTags?.findIndex(value => value.id === tag.id) === -1) {

            this.setSelectedTags([
                ...this.selectedTags,
                tag,
            ]);
        }
    };

    @action deselectTag = (tag: ITag) => {

        const index: number | undefined = this.selectedTags?.findIndex(value => value.id === tag.id);

        if (index !== undefined && this.selectedTags !== null && index !== -1) {

            this.selectedTags.splice(index, 1);
        }
    };

    @action clearSelectedTags = () => {
        this._selectedTags = null;
    }

    @computed get selectedTags() {
        return this._selectedTags;
    }

    @action setTags = (tags: ITag[]) => {

        this._tags = tags;
    };

    @action loadTags = (tagNameSearch?: string | undefined, establishment?: number[] | undefined) => {

        let url = "tags/?";

        if (tagNameSearch !== undefined && tagNameSearch.trim() !== '') {

            url += `&q=${tagNameSearch.trim()}`;
        }

        if (establishment !== undefined && establishment.length > 0) {

            url += `&establishment=${establishment.join(",")}`;
        }

        this.setIsLoading(true);
        api.get(url)
            .then((res: AxiosResponse<ITag[]>) => {

                this.setTags(res.data);
                this.setIsLoading(false);
            })
            .catch((err: AxiosError) => {

                console.log(err);
                this.setTags([]);
                this.setIsLoading(false);
            });
    };

    @action createTag = (newTag: INewTag, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.post('tags/', newTag)
            .then((res: AxiosResponse<ITag>) => {

                // todo could optimize by adding the tag rather than load up all of them again
                this.loadTags();
                setSnackbar({
                    status: 'success',
                    message: 'Тагът е създаден успешно',
                });

            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при създаването на таг',
                });
            });
    };

    @action updateTag = (updatedTag: ITag, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.patch(`tags/${updatedTag.id}`, updatedTag)
            .then((res: AxiosResponse<ITag>) => {

                // todo could optimize by updating the tag rather than load up all of them again
                this.loadTags();
                setSnackbar({
                    status: 'success',
                    message: 'Тагът е обновен успешно',
                });
            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при обновяване на таг',
                });
            })
    };

    @action deleteTag = (tag: ITag, setSnackbar: (snackbar: ISnackbar) => void) => {

        api.delete(`tags/${tag.id}`)
            .then((res: AxiosResponse<boolean>) => {

                // todo could optimize by remove the tag rather than load up all of them again
                this.loadTags();
                setSnackbar({
                    status: 'success',
                    message: 'Тагът е изтрит успешно',
                });
            })
            .catch((err: AxiosError) => {

                console.log(err);
                setSnackbar({
                    status: 'error',
                    message: 'Грешка при изтриване на таг',
                });
            });
    };

    @computed get tags() {

        return this._tags;
    }

    @action setIsLoading = (isLoading: boolean) => {

        this._isLoading = isLoading;
    };

    @computed get isLoading() {

        return this._isLoading;
    }
}

export default createContext(new TagStore());

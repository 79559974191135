import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import EstablishmentStore from "../../../stores/EstablishmentStore";
import EstablishmentsSettingsList from "./EstablishmentsSettingsList";
import EstablishmentsSettingsAdd from "./EstablishmentSettingsAdd";

const EstablishmentsSettings = () => {

    const establishmentStore = useContext(EstablishmentStore);

    if (establishmentStore.establishments === null) {

        establishmentStore.loadEstablishments();
    }

    return (
        <div>
            <EstablishmentsSettingsList/>

            <EstablishmentsSettingsAdd/>
        </div>
    );
};

export default observer(EstablishmentsSettings);

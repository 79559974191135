import React, {useContext} from "react";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {IMovement} from "../../interfaces/IMovement";
import MovementListItem from "./MovmentListItem";
import {observer} from "mobx-react-lite";
import TagStore from "../../stores/TagStore";
import MovementStore from "../../stores/MovementStore";
import FilterStore from "../../stores/FilterStore";
import {useLocation} from "react-router";
import Loading from "../common/Loading";
import EmptyList from "../common/EmptyList";
import {format} from "date-fns";
import {PERIOD_DISPLAY_DATE_FORMAT} from "../../dateFormats";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface IProps {
}

const MovementList = (props: IProps) => {

    const classes = useStyles();

    const filterStore = useContext(FilterStore);
    const tagStore = useContext(TagStore);
    const movementStore = useContext(MovementStore);

    const {search} = useLocation();
    const period: string | null = new URLSearchParams(search).get("period");

    if (period !== null && period !== filterStore.selectedPeriod && period !== undefined) {

        filterStore.setSelectedPeriod(period);
    }

    if (movementStore.movements === null) {

        if (!movementStore.isLoading) {

            movementStore.loadMovements(filterStore.selectedPeriod, tagStore.selectedTags);
        }
        return <Loading/>;
    }

    if (movementStore.movements.length === 0) {

        return <EmptyList message={`No movements for ${format(new Date(filterStore.selectedPeriod), PERIOD_DISPLAY_DATE_FORMAT)}`} />;
    }

    return (
        <React.Fragment>
            <List component="nav" className={classes.root}>
                {movementStore.movements?.map((movement: IMovement) => (
                    <React.Fragment key={`movements-list-item-${movement.id}`}>
                        <MovementListItem movement={movement}/>
                        <Divider variant="inset" component="li"/>
                    </React.Fragment>
                ))}
            </List>
        </React.Fragment>
    );
};

export default observer(MovementList);

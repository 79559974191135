import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import green from "@material-ui/core/colors/green";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import Zoom from '@material-ui/core/Zoom';
import EstablishmentsSettingsEdit from "./EstablishmentsSettingsEdit";
import {IEditItem} from "../../../interfaces/IEditItem";

interface IProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            width: 500,
            position: 'relative',
            // position: 'fixed',
            minHeight: 200,
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(5),
            right: theme.spacing(2),
        },
        fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        },
    }),
);

const EstablishmentsSettingsAdd = (props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();

    const [editItem, setEditItem] = useState<IEditItem | null>(null);

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    if (editItem) {
        return <EstablishmentsSettingsEdit editItem={editItem} setEditItem={setEditItem}/>;
    }

    return (
        <Zoom
            key="primary"
            in={true}
            timeout={transitionDuration}
            style={{
                transitionDelay: `${transitionDuration.exit}ms`,
            }}
            unmountOnExit
        >
            <Fab aria-label="add" color="primary" className={classes.fab} onClick={() => setEditItem({
                name: "",
                fieldName: "name",
                fieldLabel: "Име: ",
                type: "Получател",
                linkedWith: {
                    type: "Движение",
                    elements: [],
                }
            })}>
                <AddIcon/>
            </Fab>
        </Zoom>
    );
};

export default observer(EstablishmentsSettingsAdd);

import React, {useContext} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from '@material-ui/core/Slide';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {observer} from "mobx-react-lite";
import {TransitionProps} from "@material-ui/core/transitions";
import {IEditItem} from "../../../interfaces/IEditItem";
import MovementTextField from "../form/MovementTextField";
import MovementFormStore from "../../../stores/MovementFormStore";
import EstablishmentStore from "../../../stores/EstablishmentStore";
import SnackbarStore from "../../../stores/SnackbarStore";
import EstablishmentTagList from "./EstablishmentTagList";
import {IEstablishment} from "../../../interfaces/IEstablishment";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    editItem: IEditItem;
    setEditItem: (editItem: IEditItem | null) => void;
}

const EstablishmentsSettingsEdit = (props: IProps) => {

    const movementFormStore = useContext(MovementFormStore);
    movementFormStore.setName(props.editItem.name);

    const establishmentStore = useContext(EstablishmentStore);
    const snackbarStore = useContext(SnackbarStore);

    const establishment: IEstablishment | undefined = establishmentStore.establishments?.find(e => e.id === props.editItem.id);

    const handleCancel = () => {

        props.setEditItem(null);
    };

    const handleConfirm = () => {

        if (props.editItem.id) {

            establishmentStore.updateEstablishment({
                id: props.editItem.id,
                name: movementFormStore.getValue(props.editItem.fieldName) as string,
            }, snackbarStore.setSnackbar);

        } else {

            establishmentStore.createEstablishment({
                name: movementFormStore.getValue(props.editItem.fieldName) as string,
            }, snackbarStore.setSnackbar);
        }

        props.setEditItem(null);
    };

    const dialogTitle: string = (props.editItem.id) ?
        `Редакция на ${props.editItem.type}` :
        `Нов ${props.editItem.type}`;

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            fullScreen={true}
            open={true}
            aria-modal={true}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <MovementTextField
                            id={props.editItem.fieldName}
                            name={props.editItem.fieldName}
                            label={props.editItem.fieldLabel}
                            defaultValue={props.editItem.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <EstablishmentTagList establishment={establishment}  />
                    </Grid>
                    {props.editItem.id && <Grid item xs={12} sm={12}>
                        <Typography>
                            {props.editItem.type} {props.editItem.name} е свързан
                            с {props.editItem.linkedWith.elements.length} елемента {props.editItem.linkedWith.type}
                        </Typography>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(EstablishmentsSettingsEdit);

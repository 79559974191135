import {action, computed, observable, reaction} from "mobx";
import {createContext} from "react";
import {ISnackbar} from "../interfaces/ISnackbsar";

class SnackbarStore {
    @observable _snackbar: ISnackbar | null = null;

    constructor() {
        reaction(() => this._snackbar, _ => {});
    }

    @action setSnackbar = (snackbar: ISnackbar) => {
        this._snackbar = snackbar;
    }

    @action clearSnackbar = () => {
        this._snackbar = null;
    }

    @computed get snackbar() {
        return this._snackbar;
    }
}

export default createContext(new SnackbarStore());

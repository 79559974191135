import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import AddMovement from "../../components/mobile/AddMovement";
import Grid from "@material-ui/core/Grid";
import MovementList from "../../components/mobile/MovementList";
import {makeStyles} from "@material-ui/core/styles";
import MobileNavigation from "../../components/mobile/MobileNavigation";
import {RouteComponentProps} from "react-router-dom";
import FilterForm from "../../components/common/filters/FilterForm";
import FilterStore from "../../stores/FilterStore";
import FiltersContainer from "../../components/common/filters/FiltersContainer";

interface IProps {
    route: RouteComponentProps;
}

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        height: "100vh",
    },
    topPanel: {
        flex: 1,
        borderTop: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
    bottomPanel: {
        flex: 10,
        overflow: "auto",
    },
    middlePanel: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        borderBottom: "solid 1px #ebebeb",
        backgroundColor: "#ffffff",
    },
}));

const MobileHomePage = (props: IProps) => {

    const classes = useStyles();

    const filterStore = useContext(FilterStore);

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid item xs={12} className={classes.topPanel}>
                <MobileNavigation route={props.route}/>
            </Grid>
            <Grid item xs={12} className={classes.middlePanel}>
                <FiltersContainer route={props.route}/>
            </Grid>
            <Grid item xs={12} className={classes.bottomPanel}>
                <MovementList/>
            </Grid>
            <AddMovement/>
            {filterStore.isFormOpen && <FilterForm/>}
        </Grid>
    );
};

export default observer(MobileHomePage);
